import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import AlertsSummary from './components/AlertsSummary';
import AlertList from './components/AlertList';
import AlertSearch from './components/AlertSearch';
import './index.css';
import 'antd/dist/reset.css';

//TODO:  Use react query to enable caching on the service calls

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/summary',
        element: <AlertsSummary />,
      },
      {
        path: '/list',
        element: <AlertList />,
      },
      {
        path: '/search',
        element: <AlertSearch />,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
