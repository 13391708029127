import React, { useState } from 'react';
import { Badge, Button, Card, DatePicker, Empty, Form, Input, Space, Statistic } from 'antd';
import { AlertsAPI } from '../apis/alertsAPI';
import { v4 as uuidv4 } from 'uuid';
import ISearchMetricResult from '../types/Search';
import Loading from './Loading';
import Error from './Error';

const { RangePicker } = DatePicker;

const AlertSearch = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<ISearchMetricResult[]>([]);
  const [resultsCount, setResultsCount] = useState<number>(0);
  const [error, setError] = useState<string | undefined>(undefined);

  const onReset = () => {
    form.resetFields();
    setResults([]);
    setResultsCount(0);
  };

  const handleErrorClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setError(undefined);
  };

  const onFinish = (values: any) => {
    const fromDate = values.searchRange[0].format('MM/DD/YYYY');
    const toDate = values.searchRange[1].format('MM/DD/YYYY');
    const searchText = values.searchText;

    setLoading(true);

    AlertsAPI.getSearchResults(fromDate, toDate, searchText)
      .then((results) => {
        let sum: number = 0;

        results.forEach((r) => (sum += r.cleanTextCount));

        setResults(results);
        setResultsCount(sum);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <div
          style={{
            width: '100%',
            marginTop: '25px',
          }}
        >
          <Error description={error} onClose={handleErrorClose} />
          <Form
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 20 }}
            autoComplete='off'
            style={{ maxWidth: 800 }}
            onFinish={onFinish}
          >
            <Form.Item name='searchRange' label='Search Range' rules={[{ required: true }]}>
              <RangePicker />
            </Form.Item>
            <Form.Item name='searchText' label='Search Text' rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 6 }}>
              <Button type='primary' htmlType='submit' style={{ marginRight: '8px' }}>
                Submit
              </Button>
              <Button htmlType='button' onClick={onReset}>
                Reset
              </Button>
            </Form.Item>
          </Form>
          {results && results.length === 0 && <Empty />}
          {results && results.length > 0 && (
            <div style={{ margin: '35px 85px' }}>
              <Card style={{ width: '250px', marginBottom: '20px' }}>
                <Statistic title='Total Results' value={resultsCount} />
              </Card>
              <Space direction='vertical' size='middle'>
                {results.map((result) => {
                  return (
                    <Badge.Ribbon text={result.cleanTextCount} key={uuidv4()}>
                      <Card title='Alert Text' size='small'>
                        {result.cleanText}
                      </Card>
                    </Badge.Ribbon>
                  );
                })}
              </Space>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AlertSearch;
