import React from 'react';
import { AlertsAPI } from '../apis/alertsAPI';
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { DatePicker, Image } from 'antd';
import { useNavigate } from 'react-router-dom';
import IMetricData from '../types/Metric';
import Loading from './Loading';
import dayjs from 'dayjs';
import NoDataFound from '../assets/no-data-found-transparent.png';
import Error from './Error';
import RenderCell from '../utils/rendercell';

const AlertsSummary = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [metrics, setMetrics] = React.useState<Array<IMetricData>>([]);
  const [distinctMetrics, setDistinctMetrics] = React.useState<Array<IMetricData>>([]);
  const [currentYearMonth, setCurrentYearMonth] = React.useState<string>('');
  const [selectedMonth, setSelectedMonth] = React.useState<dayjs.Dayjs | null>(null);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const monthFormat = 'YYYY-MM';
  const navigate = useNavigate();

  React.useEffect(() => {
    const date = new Date();

    setLoading(true);
    setCurrentYearMonth(date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0'));
    setSelectedMonth(
      dayjs(date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0'), monthFormat)
    );

    Promise.all([
      AlertsAPI.getMetrics(date.getMonth() + 1, date.getFullYear()),
      AlertsAPI.getDistinctMetrics(),
    ])
      .then(([metrics, distinctMetrics]) => {
        setMetrics(metrics);
        setDistinctMetrics(distinctMetrics);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleClick = (data: any, index: number) => {
    navigate('/list', { state: { data: data.payload } });
  };

  const handleMonthChange = (date: dayjs.Dayjs | null, dateString: string) => {
    setSelectedMonth(date);

    if (!date) {
      setError(
        '(handleMonthChange) Unable to retrieve data for the selected month due to date being null.'
      );
      setMetrics([]);
      return;
    }

    setLoading(true);

    AlertsAPI.getMetrics(date.month() + 1, date.year())
      .then((metrics) => {
        setMetrics(metrics);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleErrorClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setError(undefined);
  };

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <div
          style={{
            width: '100%',
            marginTop: '25px',
          }}
        >
          <Error description={error} onClose={handleErrorClose} />
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <span style={{ fontWeight: 'bold' }}>SELECT MONTH:{'  '}</span>
            <DatePicker
              picker='month'
              defaultValue={dayjs(currentYearMonth, monthFormat)}
              style={{ marginLeft: '10px' }}
              onChange={handleMonthChange}
              value={selectedMonth}
              cellRender={(current) => RenderCell(current, distinctMetrics, selectedMonth)}
            />
          </div>
          {metrics.length <= 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '25px',
              }}
            >
              <Image src={NoDataFound} preview={false} alt='No Data Found' />
            </div>
          )}
          {metrics.length > 0 && (
            <div style={{ width: '75%', margin: '0 auto' }}>
              <ResponsiveContainer width='100%' height={500} minWidth='75%'>
                <BarChart
                  width={500}
                  height={300}
                  data={metrics}
                  style={{ marginTop: '25px', cursor: 'pointer' }}
                >
                  <XAxis dataKey='day' />
                  <YAxis label={{ value: '# of Alerts', angle: -90, position: 'insideLeft' }} />
                  <Bar dataKey='total' fill='#1677ff' onClick={handleClick} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AlertsSummary;
