import axios from 'axios';
import { config } from '../../utils/constants';

export const api = axios.create({
  baseURL: config.url.API_URL,
  withCredentials: false,
});

const errorHandler = (error: any) => {
  const statusCode = error.response?.status;

  if (statusCode && statusCode !== 401) {
    console.error(error);
  }

  return Promise.reject(error);
};

api.interceptors.response.use(undefined, (error) => errorHandler(error));
