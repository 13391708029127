import React from 'react';
import { Alert } from 'antd';

type Props = {
  description: string | undefined;
  onClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const Error = ({ description, onClose }: Props) => {
  return description !== undefined ? (
    <Alert
      message='Error'
      description={description}
      type='error'
      showIcon
      closable
      style={{ width: '40%', margin: '0 auto', marginBottom: '25px' }}
      onClose={onClose}
    />
  ) : null;
};

export default Error;
