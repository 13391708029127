import { api } from './configs/axiosConfig';
import IAlertData from '../types/Alert';
import IMetricData from '../types/Metric';
import ISearchMetricResult from '../types/Search';

export const AlertsAPI = {
  getDistinctMetrics: async function () {
    const response = await api.request({
      url: `GetDistinctMetrics`,
      method: 'GET',
    });

    return response.data as Array<IMetricData>;
  },
  getMetrics: async function (month: number, year: number) {
    const response = await api.request({
      url: `GetMetrics?month=${month}&year=${year}`,
      method: 'GET',
    });

    return response.data as Array<IMetricData>;
  },
  getAlerts: async function (month: number, day: number, year: number) {
    const response = await api.request({
      url: `GetAlerts?month=${month}&day=${day}&year=${year}`,
      method: 'GET',
      data: {
        month,
        day,
        year,
      },
    });

    return response.data as Array<IAlertData>;
  },
  getSearchResults: async function (fromDate: string, toDate: string, searchText: string) {
    const response = await api.request({
      url: `GetSearchMetrics?fromDate=${fromDate}&toDate=${toDate}&searchText=${searchText}`,
      method: 'GET',
      data: {
        fromDate,
        toDate,
        searchText,
      },
    });

    return response.data as Array<ISearchMetricResult>;
  },
};
