import React from 'react';
import { useLocation } from 'react-router-dom';
import { AlertsAPI } from '../apis/alertsAPI';
import { List } from 'antd';
import { AlertOutlined } from '@ant-design/icons';
import IAlertData, { IDistinctAlertData } from '../types/Alert';
import Collapsible from 'react-collapsible';
import Error from './Error';
import './AlertList.css';

const AlertList = () => {
  const [alerts, setAlerts] = React.useState<Array<IAlertData>>([]);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [uniqueAlerts, setUniqueAlerts] = React.useState<Array<IDistinctAlertData>>([]);
  const location = useLocation();
  const { month, day, year, total } = location.state.data;

  React.useEffect(() => {
    if (alerts.length <= 0) {
      AlertsAPI.getAlerts(month, day, year)
        .then((alerts) => setAlerts(alerts))
        .catch((error) => setError(error.message));
    }
  }, [alerts.length, day, month, year]);

  React.useEffect(() => {
    if (alerts.length > 0) {
      const uniqueAlertText = [...new Set(alerts.map((alert) => alert.text))];
      const uniqueAlerts: Array<IDistinctAlertData> = uniqueAlertText.map((text) => {
        const count = alerts.filter((alert) => alert.text === text).length;
        return { text, count };
      });

      setUniqueAlerts(uniqueAlerts);
    }
  }, [alerts]);

  const handleErrorClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setError(undefined);
  };

  return (
    <div>
      <Error description={error} onClose={handleErrorClose} />
      <Collapsible trigger={`> Unique Alerts For ${month}/${day}/${year}`} easing='ease-in' open>
        <List
          itemLayout='horizontal'
          bordered
          dataSource={uniqueAlerts.sort((a, b) => b.count - a.count)}
          style={{ width: '75%', margin: '0 auto', marginTop: '2rem', marginBottom: '2rem' }}
          header={
            <div>
              <h2>
                Alerts For {month}/{day}/{year}
              </h2>
              <h3>Total Unique Alerts: {uniqueAlerts.length}</h3>
            </div>
          }
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                avatar={<AlertOutlined />}
                title={`Alert Count:  ${item.count}`}
                description={item.text}
              />
            </List.Item>
          )}
        />
      </Collapsible>
      <Collapsible trigger={`> All Alerts For ${month}/${day}/${year}`} easing='ease-in'>
        <List
          itemLayout='horizontal'
          bordered
          dataSource={alerts}
          style={{ width: '75%', margin: '0 auto', marginTop: '2rem', marginBottom: '2rem' }}
          header={
            <div>
              <h2>
                Alerts For {month}/{day}/{year}
              </h2>
              <h3>Total Alerts: {total}</h3>
            </div>
          }
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                avatar={<AlertOutlined />}
                title={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <h4>{item.title !== '' ? item.title : 'Alert'}</h4>
                    <h4>{item.localTS}</h4>
                  </div>
                }
                description={
                  <div>
                    <p>{item.text}</p>
                    {item.fields.length > 0 && (
                      <div>
                        <h4>Fields</h4>
                        <ul>
                          {item.fields.map((field, index) => (
                            <li key={index}>
                              <strong>{field.title}</strong>: {field.value}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </Collapsible>
    </div>
  );
};

export default AlertList;
