import * as React from 'react';
import { AZADAuthContextProvider } from './contexts/ActiveDirectoryAuthContext';
import { Image, Layout, Menu, MenuProps } from 'antd';
import { AlertOutlined, SearchOutlined } from '@ant-design/icons';
import { Outlet, useNavigate } from 'react-router-dom';
import { getItem, MenuItem } from './utils/menu';
import Logo from './assets/prodalerts-small.png';

function App() {
  const [collapsed, setCollapsed] = React.useState(false);
  const { Content, Sider } = Layout;
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate('/summary');
  }, []);

  const items: MenuItem[] = [
    getItem('Alert Summary', 'summary', <AlertOutlined />),
    getItem('Alerts Search', 'search', <SearchOutlined />),
  ];

  const onClick: MenuProps['onClick'] = ({ key }) => {
    navigate(`/${key}`);
  };

  return (
    <AZADAuthContextProvider>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
          <Image src={Logo} preview={false} style={{ height: '44px', margin: '16px 0' }} />
          <Menu
            onClick={onClick}
            theme='dark'
            mode='inline'
            items={items}
            defaultSelectedKeys={['summary']}
          />
        </Sider>
        <Layout className='site-layout'>
          <Content style={{ margin: '0 16px' }}>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </AZADAuthContextProvider>
  );
}

export default App;
