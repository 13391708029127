import dayjs from 'dayjs';
import IMetricData from '../types/Metric';

export function RenderCell(
  current: dayjs.Dayjs,
  distinctMetrics: Array<IMetricData>,
  selectedMonth: dayjs.Dayjs | null
): React.ReactNode {
  const style: React.CSSProperties = {};

  style.color = 'white';

  if (
    distinctMetrics.findIndex(
      (m) => m.month === current.month() + 1 && m.year === current.year()
    ) !== -1 ||
    (selectedMonth &&
      current.month() + 1 === selectedMonth.month() + 1 &&
      current.year() === selectedMonth.year())
  ) {
    style.backgroundColor =
      selectedMonth && current.month() + 1 === selectedMonth.month() + 1 ? '#1677ff' : '#000000';
  } else {
    style.backgroundColor = '#ffffff';
    style.color = '#000000';
    style.border = '1px solid #000000';
  }

  return (
    <div className='ant-picker-cell-inner' style={style}>
      {dayjs(current).format('MMM')}
    </div>
  );
}

export default RenderCell;
