const prod = {
  url: {
    API_URL: 'https://king-ninja-prod-alert-processor-fn.azurewebsites.net/api/',
  },
};

const dev = {
  url: {
    API_URL: 'http://localhost:7243/api/',
  },
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
