import React from 'react';
import { MrMiyagi } from '@uiball/loaders';

const Loading = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        fontSize: '1.25rem',
        fontWeight: 'bold',
      }}
    >
      <MrMiyagi size={50} color='#1677ff' />
      Loading...
    </div>
  );
};

export default Loading;
