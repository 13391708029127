import React, { useContext, useEffect, useState } from 'react';
import AuthenticationContext from 'adal-angular';

interface AZADAuthContextValue {
  loggedIn: boolean;
  user: AuthenticationContext.UserInfo;
  token: string | null;
}

export const AZADAuthContext = React.createContext<AZADAuthContextValue | null>(null);

const authContextConfig = {
  clientId: '395ab9ee-e1b4-432d-b103-d7fa81e1acee',
};

const authContext = new AuthenticationContext(authContextConfig);

export const AZADAuthContextProvider = (props: { children: React.ReactNode }) => {
  const [loggedIn, setLoggedIn] = useState(!!authContext.getCachedUser());
  const [token, setToken] = useState(authContext.getCachedToken(authContext.config.clientId));

  useEffect(() => {
    const handleAuthCallback = (
      errorDescription: string | null,
      token: string | null,
      error: string | null
    ) => {
      if (!error && token) {
        setToken(token);
      } else if (error === 'interaction_required') {
        authContext.acquireTokenPopup(authContext.config.clientId, null, null, handleAuthCallback);
      }
    };

    if (authContext.isCallback(window.location.hash)) {
      authContext.handleWindowCallback(window.location.hash);
      setLoggedIn(true);
    } else if (!loggedIn) {
      authContext.login();
    } else {
      authContext.acquireToken(authContext.config.clientId, handleAuthCallback);
    }
  }, [loggedIn]);

  const contextValue = {
    loggedIn: loggedIn,
    user: authContext.getCachedUser(),
    token: token,
  };

  return (
    <AZADAuthContext.Provider value={contextValue}>
      {loggedIn ? props.children : null}
    </AZADAuthContext.Provider>
  );
};

export const useAZADAuthContext = () => {
  const auth = useContext(AZADAuthContext);

  if (!auth) {
    throw new Error('Must be used inside an AZADAuthContextProvider');
  }

  return auth;
};

export const AZADAuthContextConsumer = (props: {
  children: (auth: AZADAuthContextValue) => React.ReactNode;
}) => {
  return (
    <AZADAuthContext.Consumer>
      {(auth) => {
        if (!auth) {
          throw new Error('Must be used inside an AZADAuthContextProvider');
        }

        return props.children(auth);
      }}
    </AZADAuthContext.Consumer>
  );
};
